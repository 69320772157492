export default function (Alpine) {
    Alpine.data('recaptcha', (action, recaptcha = null) => ({
        action: action,
        GOOGLE_RECAPTCHA_SITE: recaptcha,
        recaptchaToken: null,

        async submit() {
            if (null !== this.GOOGLE_RECAPTCHA_SITE && '' !== this.GOOGLE_RECAPTCHA_SITE) {
                this.recaptchaToken = await grecaptcha.execute(this.GOOGLE_RECAPTCHA_SITE, {
                    action: this.action
                });
                this.$wire.dispatch('setRecaptchaToken', { token: this.recaptchaToken, submit: true });
            } else {
                this.$wire.submit();
            }
        },
    }));
}
