import Swiper from 'swiper';
import { Navigation, Pagination, Keyboard, Autoplay } from 'swiper/modules';
import 'swiper/css';

export default function (Alpine) {
    Alpine.data('swiper', () => ({
        isOpen: false,
        swiperDialog: null,
        imageGrid: null,
        unitDetailSwiper: null,
        fullImageSwiper: null,
        filamentCompanySwiper: null,

        stackId: 0,

        init() {
            const swipers = this.$el.querySelectorAll('.swiper');

            swipers.forEach((swiper) => {
                const ref = swiper.getAttribute('x-ref');
                const prevButton = swiper.querySelector('.swiper-button-prev');
                const nextButton = swiper.querySelector('.swiper-button-next');
                const pagination = swiper.querySelector('.swiper-pagination');
                const swiperOptions = {
                    modules: [Navigation, Pagination, Keyboard, Autoplay],
                    keyboard: {
                        enabled: true,
                    },
                    loop: (swiper.hasAttribute('data-swiper-loop') ? (swiper.getAttribute('data-swiper-loop') === 'true') : false),
                    autoHeight: (swiper.hasAttribute('data-swiper-autoheight') ? (swiper.getAttribute('data-swiper-autoheight') === 'true') : false),
                    pagination: {
                        el: pagination,
                        bulletActiveClass: "!bg-red",
                        bulletClass: "inline-block w-4 h-4 cursor-pointer rounded-full overflow-hidden transition-colors duration-200 ease-out hover:bg-red hover:shadow-lg bg-white",
                        clickable: true,
                    },
                    navigation: {
                        disabledClass: 'hidden',
                        nextEl: nextButton,
                        prevEl: prevButton,
                    },
                    autoplay: (swiper.hasAttribute('data-swiper-autoplay') ? {
                        delay: swiper.getAttribute('data-swiper-autoplay'),
                        stopOnLastSlide: true
                    } : false)
                }
                const initSwiper = () => {
                    switch (ref) {
                        case 'swiperDialog': {
                            this.swiperDialog = new Swiper(this.$refs.swiperDialog, swiperOptions);
                            break;
                        }
                        case 'imageGrid': {
                            this.imageGrid = new Swiper(this.$refs.imageGrid, swiperOptions);
                            break;
                        }
                        case 'unitDetailSwiper': {
                            this.unitDetailSwiper = new Swiper(this.$refs.unitDetailSwiper, swiperOptions);
                            break;
                        }
                        case 'fullImageSwiper': {
                            this.fullImageSwiper = new Swiper(this.$refs.fullImageSwiper, swiperOptions);
                            break;
                        }
                        case 'filamentCompanySwiper': {
                            this.filamentCompanySwiper = new Swiper(this.$refs.filamentCompanySwiper, swiperOptions);
                            break;
                        }
                    }
                };

                if (swiper.hasAttribute('data-swiper-intersect')) {
                    new IntersectionObserver((entries, observer) => {
                        if (entries[0].isIntersecting) {
                            observer.disconnect();
                            initSwiper();
                        }
                    }).observe(swiper);
                } else {
                    initSwiper();
                }
            })
        },

        close() {
            window.stack.popStack(this.stackId);
            this.isOpen = false;
        },

        open(index, ref) {
            this.stackId = window.stack.pushStack();
            if(index !== undefined) {
                switch (ref) {
                    case 'imageGrid': {
                        this.imageGrid.slideTo(index);
                        break;
                    }
                    case 'unitDetailSwiper': {
                        this.unitDetailSwiper.slideTo(index);
                        break;
                    }
                    case 'fullImageSwiper': {
                        this.fullImageSwiper.slideTo(index);
                        break;
                    }
                    case 'filamentCompanySwiper': {
                        this.filamentCompanySwiper.slideTo(index);
                        break;
                    }
                }
            }
            this.isOpen = true;
        }
    }))
}
