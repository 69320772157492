import Swiper from 'swiper';
import { Navigation, Pagination, Keyboard } from 'swiper/modules';
import 'swiper/css';

export default function (Alpine) {
    Alpine.data('filamentCompanyPage', () => ({
        swiper: null,

        init() {
            this.swiper = new Swiper(this.$refs.swiper, {
                modules: [Navigation, Pagination, Keyboard],
                keyboard: {
                    enabled: true,
                },
                loop: true,
                pagination: {
                    el: ".swiper-pagination",
                    bulletActiveClass: "!bg-red",
                    bulletClass: "inline-block w-4 h-4 cursor-pointer rounded-full overflow-hidden transition-colors duration-200 ease-out hover:bg-red hover:shadow-lg bg-white",
                    clickable: true,
                },
                navigation: {
                    disabledClass: 'hidden',
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
            });
        },
    }))
}
