export default function (Alpine) {
    Alpine.data('geoLocation', function () {
        return {
            latitude: null,
            longitude: null,
            error: null,

            // Function to get the user's location
            askPermission() {
                if (navigator.geolocation) {
                    this.$wire.needsRefresh().then((needsRefresh) => {
                        // Only request new location if we actually need it
                        if (needsRefresh) {
                            navigator.permissions
                                .query({name: "geolocation"})
                                .then((permissionStatus) => {
                                    this.setPermission(permissionStatus.state);
                                    permissionStatus.onchange = () => {
                                        this.setPermission(permissionStatus.state);
                                    };
                                });
                        }
                    });
                }
            },

            setPermission(state) {
                switch (state) {
                    case "denied":
                    case "prompt" || "granted": {
                        navigator.geolocation.getCurrentPosition(this.showPosition.bind(this), this.showError.bind(this), {
                            enableHighAccuracy: true
                        });
                        break;
                    }
                    default: {
                        this.error = 'Unknown geolocation permission state';
                        break;
                    }
                }
            },

            // Function to handle a successful location retrieval
            showPosition(position) {
                let positionArray = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };
                this.$wire.updateLocation(positionArray);
            },

            showError(error) {
                // Code 1 is permission denied
                if (error.code === 1) {
                    this.$wire.updateLocation(null);
                }
            },

            cookieExists() {
                return (document.cookie.split('; ').indexOf('nieuwbouwnl-cookie=' + 1) !== -1);
            }
        };
    });
}
