const GPT_ACCOUNT_KEY = '23142817285'

const GPT_INVENTORY = {
    'default-horizontal-bar': {
        'sizes': [[728, 90], [320, 100]],
        'sizeMapping': [
            { viewport: [768, 0], sizes: [728, 90] },
            { viewport: [0, 0], sizes: [320, 100] }
        ]
    },
    'default-vertical-bar': {
        'sizes': [[300, 600]]
    },
    'default-sidebar': {
        'sizes': [[300, 600], [300, 250]]
    }
}

let gptAdsFound = false;

function initAds() {
    const adDivs = document.querySelectorAll('[id^="div-gpt-ad"]');

    adDivs.forEach((adDiv) => {
        const adId = adDiv.getAttribute('id');
        const adUnit = adDiv.getAttribute('data-gpt-ad-unit');

        if(Object.keys(GPT_INVENTORY).includes(adUnit)) {
            gptAdsFound = true;

            const inventoryItem = GPT_INVENTORY[adUnit];

            googletag.cmd.push(function () {
                let slot = googletag.defineSlot(
                    `/${GPT_ACCOUNT_KEY}/${adUnit}`,
                    inventoryItem['sizes'],
                    adId
                );

                if (inventoryItem.sizeMapping) {
                    const sizeMappingBuilder = googletag.sizeMapping();

                    inventoryItem.sizeMapping.forEach(mapping => {
                        sizeMappingBuilder.addSize(mapping.viewport, mapping.sizes);
                    });

                    const sizeMapping = sizeMappingBuilder.build();
                    slot = slot.defineSizeMapping(sizeMapping);
                }


                slot.setCollapseEmptyDiv(true, true)
                slot.addService(googletag.pubads());

                googletag.display(adId);
            });
        } else {
            adDiv.remove()
        }
    });
}

// Initialiseer alle advertenties als de pagina geladen is
document.addEventListener("DOMContentLoaded", function () {
    initAds();
});

if(gptAdsFound) {
    /**
     * Debounce function for refreshing ads on resize window
     */
    let resizeTimeout;
    window.addEventListener('resize', function () {
        clearTimeout(resizeTimeout);
        resizeTimeout = setTimeout(function () {
            googletag.cmd.push(function () {
                googletag.pubads().refresh();
            });
        }, 250);
    });
}
