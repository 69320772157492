export default function (Alpine) {
    Alpine.data('modal', () => ({
        isOpen: false,
        stackId: undefined,

        open() {
            this.stackId = window.stack.pushStack();
            this.isOpen = true;
        },

        close() {
            if (this.stackId && window.stack.popStack(this.stackId)) {
                this.isOpen = false;
                return true;
            }
            return false;
        },
    }));
}
